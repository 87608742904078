'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import HttpError from '@/src/shared/api/http-error';
import SomethingWentWrong from '@/src/shared/components/SomethingWentWrong/SomethingWentWrong';
const Error: unknown = ({
  error
  // reset,
}: {
  error: Error & HttpError & {
    digest?: string;
  };
  reset: () => void;
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <SomethingWentWrong error={error} data-sentry-element="SomethingWentWrong" data-sentry-component="Error" data-sentry-source-file="error.tsx" />;
};
export default Error;