'use client';

import { useTranslations } from 'next-intl';
import React, { ComponentType } from 'react';
import HttpError from '@/src/shared/api/http-error';
import Image from '@/src/shared/components/Image/Image';
import RefreshButton from '@/src/shared/components/StaticErrorMessage/RefreshButton/RefreshButton';
interface Props {
  error?: Error & HttpError & {
    digest?: string;
  };
}
const SomethingWentWrong: ComponentType<Props> = ({
  error
}) => {
  const t = useTranslations();
  return <div className="w-full min-h-full flex items-center justify-center col-span-full p-4 sm:p-6" data-sentry-component="SomethingWentWrong" data-sentry-source-file="SomethingWentWrong.tsx">
      <div className="flex flex-col items-center text-center ">
        <div className="relative aspect-[582/978] w-[130px] mb-4">
          <Image src="/assets/images/anime-girl-guilty.png" alt={t('somethingWentWrong.title')} className="object-cover" fill priority sizes="130px" data-sentry-element="Image" data-sentry-source-file="SomethingWentWrong.tsx" />
          <div className="absolute inset-0 z-10 bg-gradient-to-t from-bgBase-500 to-40%" />
        </div>
        <h1 className="text-3xl sm:text-4xl font-bold text-primary-500 mb-4 max-w-xl">
          {t.rich('somethingWentWrong.title', {
          br: () => <br />
        })}
        </h1>
        <p className="text-sm sm:text-base mb-6 max-w-lg">
          {t('somethingWentWrong.description')}
        </p>
        <RefreshButton size="large" data-sentry-element="RefreshButton" data-sentry-source-file="SomethingWentWrong.tsx" />
        {error && <p className="text-xs sm:text-base mt-6 max-w-lg opacity-30">
            {error?.message ?? error?.digest}
          </p>}
      </div>
    </div>;
};
export default SomethingWentWrong;