'use client';

import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
export interface ImageProps extends NextImageProps {
  fallbackSrc?: string;
}
const Image: ComponentType<ImageProps> = ({
  fallbackSrc = '/assets/images/fallback-image.png',
  ...props
}) => {
  const [src, setSrc] = useState<string | StaticImport>(props.src);
  useEffect(() => {
    setSrc(props.src);
  }, [props.src]);
  const handleError = useCallback(() => {
    setSrc(fallbackSrc);
  }, [fallbackSrc]);
  return <NextImage quality={80} {...props} src={src} onError={handleError} data-sentry-element="NextImage" data-sentry-component="Image" data-sentry-source-file="Image.tsx" />;
};
export default Image;